import {useEffect, useState} from 'react'

export default function useWindowSize(customWidth?: number) {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    })
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const isSm = windowSize.width && windowSize.width <= 599
    const isMd = windowSize.width && windowSize.width <= 1023
    const isLg2 = windowSize.width && windowSize.width <= 1325
    const isLg = windowSize.width && windowSize.width <= 1439
    const isCustom = customWidth && windowSize.width && windowSize.width <= customWidth

    return {windowSize, isSm, isMd, isLg, isLg2, isCustom}
}
