const useView = () => {
    const renderBlur = (condition?: boolean) => {
        if (condition === true) return 'blur-sm'
        else return ''
    }

    return {renderBlur}
}

export default useView
