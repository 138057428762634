import {apiFollow} from '@api/user/profile/follow'
import useSnackbar from '@hook/useSnackbar'
import {createLog} from '@util/logs'

const usePortfolio = () => {
    const {showErrorSnackbar} = useSnackbar()

    const followUser = async (user_id?: string, refreshCallback?: () => void) => {
        const {data, error} = await apiFollow(user_id)

        if (!data) {
            createLog('error', 'Follow_User_Error')
            showErrorSnackbar(error)
        } else {
            createLog('event', 'Follow_User_Success', {follow_user_id: user_id})
        }
        refreshCallback && refreshCallback()
    }
    return {followUser}
}

export default usePortfolio
