import {create} from 'zustand'
import {ISearchUser} from '@api/leaderboard/search/getSearchUser'
import {persist} from 'zustand/middleware'

interface IRecentSearchUserHistoryStore {
    data: ISearchUser[]
    getRecentUsers: () => ISearchUser[]
    saveRecentUser: (user: ISearchUser) => void
    removeRecentUser: (id: string) => void
    removeRecentUserAll: () => void
}

export const useSearchUserHistoryStore = create<IRecentSearchUserHistoryStore>()(
    persist(
        (set, get) => ({
            data: [],
            getRecentUsers: () => {
                return get()?.data ?? []
            },
            saveRecentUser: user => {
                const list = get()?.data

                // search
                const searchedItemIndex = list.findIndex(item => item?.id === user.id)
                const searchedItem = list[searchedItemIndex]

                if (searchedItem) {
                    // 기존 내역이 존재하면, 최신 정보로 업데이트
                    list[searchedItemIndex] = user
                    list.splice(searchedItemIndex, 1)
                    list.unshift(user)
                } else {
                    // 기존 내역이 없다면, 최상단에 추가하기
                    list.unshift(user)
                }

                // 아이템 개수가 5개를 초과하면 이전 내역 삭제
                if (list.length > 5) {
                    list.splice(5)
                }

                set(state => ({data: list}))
            },
            removeRecentUser: id => {
                const list = get()?.data

                const searchedItemIndex = list.findIndex(item => item?.id === id)
                if (searchedItemIndex === -1) return

                list.splice(searchedItemIndex, 1)

                set(state => ({data: list}))
            },
            removeRecentUserAll: () => {
                set(state => ({data: []}))
            },
        }),
        {
            name: 'search-user-history-storage',
        },
    ),
)
