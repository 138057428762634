import {useEffect, useState} from 'react'

const KEY_ENABLE_HIDE_SMALL_BALANCE = 'KEY_ENABLE_HIDE_SMALL_BALANCE'

const usePreference = () => {
    const [isEnableHideSmallBalance, setEnableHideSmallBalance] = useState(false)

    useEffect(() => {
        setEnableHideSmallBalance(localStorage?.getItem(KEY_ENABLE_HIDE_SMALL_BALANCE) === 'true')
    }, [])

    const setHideSmallBalance = (enable: boolean) => {
        localStorage?.setItem(KEY_ENABLE_HIDE_SMALL_BALANCE, enable?.toString())
        setEnableHideSmallBalance(enable)
    }

    return {setHideSmallBalance, isEnableHideSmallBalance}
}

export default usePreference
