import {getCookie, setCookie} from '@util/cookie'

const useTotpRegister = () => {
    const saveEmailVerificationCode = (code: string) => {
        setCookie('2fa_email_code', code)
    }

    const loadEmailVerificationCode = (): string => {
        return getCookie('2fa_email_code')
    }

    return {saveEmailVerificationCode, loadEmailVerificationCode}
}

export default useTotpRegister
