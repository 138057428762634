import {useLocalStorage} from 'usehooks-ts'
import {COPY_LEADER_BOARD_FILTER_KEY, MAIN_LEADER_BOARD_FILTER_KEY, RANK_LEADER_BOARD_FILTER_KEY} from '@constant/key'
import {
    RankKind,
    RankPeriod,
    RankSortBy,
} from '@feature/leaderboard/pages/ScreenMain/MainLeaderboardHeaders/MainLeaderboardOptionContainer'
import {ExchangeType} from '@type/exchange'

export const LeaderBoardFilterKeys = {
    MainLeaderBoardKey: MAIN_LEADER_BOARD_FILTER_KEY,
    CopyLeaderBoardKey: COPY_LEADER_BOARD_FILTER_KEY,
    RankLeaderBoardKey: RANK_LEADER_BOARD_FILTER_KEY,
}
export type LeaderBoardFilterKeyType = typeof LeaderBoardFilterKeys[keyof typeof LeaderBoardFilterKeys]
export type TLeaderBoardFilter = {
    exchange?: 'all' | ExchangeType
    period?: RankPeriod
    rankKind?: RankKind
    sortBy?: RankSortBy
}

export type TCopyLeaderBoardFilter = {
    filter?: boolean
} & TLeaderBoardFilter

// Main Leader Board Filter
export const DEFAULT_MAIN_LEADER_BOARD_FILTER: TLeaderBoardFilter = {
    exchange: 'all',
    period: '1000',
    rankKind: 'normal',
    sortBy: 'profit',
}
export const useMainLeaderBoardFilter = () => {
    const [mainFilter, setMainFilter] = useLocalStorage<TLeaderBoardFilter>(
        LeaderBoardFilterKeys.MainLeaderBoardKey,
        DEFAULT_MAIN_LEADER_BOARD_FILTER,
    )

    return {mainFilter, setMainFilter}
}

// Copy Leader Board Filter
export const DEFAULT_COPY_LEADER_BOARD_FILTER: TCopyLeaderBoardFilter = {
    exchange: 'all',
    period: '1000',
    filter: false,
}
export const useCopyLeaderBoardFilter = (key?: LeaderBoardFilterKeyType) => {
    const [copyFilter, setCopyFilter] = useLocalStorage<TCopyLeaderBoardFilter>(
        LeaderBoardFilterKeys.CopyLeaderBoardKey,
        DEFAULT_COPY_LEADER_BOARD_FILTER,
    )

    return {copyFilter, setCopyFilter}
}

// Rank Leader Board Filter
export const DEFAULT_RANK_LEADER_BOARD_FILTER: TLeaderBoardFilter = {
    rankKind: 'normal',
}
export const useRankLeaderBoardFilter = (key?: LeaderBoardFilterKeyType) => {
    const [RankFilter, setRankFilter] = useLocalStorage(
        LeaderBoardFilterKeys.RankLeaderBoardKey,
        DEFAULT_RANK_LEADER_BOARD_FILTER,
    )

    return {RankFilter, setRankFilter}
}
