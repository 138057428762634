import {useLoginModalStore} from '@store/LoginModalStore'
import {useAlertModalStore} from '@store/AlertModalStore'
import {IAlertModal} from '@component/modal/AlertModal'

const useModal = () => {
    const setAlertModal = useAlertModalStore(state => state.setAlertModal)

    const showLoginModal = useLoginModalStore(state => state.showLoginModal)
    const showAlertModal = (props: IAlertModal) => {
        setAlertModal(props)
    }

    return {showLoginModal, showAlertModal}
}

export default useModal
