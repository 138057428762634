import {createLog} from '@util/logs'

const useCopy = () => {
    const copyToClipboard = async (text?: string) => {
        await navigator?.clipboard?.writeText(text ?? '')
    }

    return {copyToClipboard}
}

export default useCopy
