import {useLoginStore} from '@store/LoginStore'
import {UserPrivacy} from '@type/profile'
import {useMemo} from 'react'

const usePortfolioPrivacy = (
    privacy_level: number = 0,
    is_following: boolean = false,
    id: string,
    is_hidden: boolean = false,
) => {
    const login_id = useLoginStore(state => state.id)
    /*
        포트폴리오의 일부를 가릴 때 사용한다.
     */
    const isHidePortfolio = useMemo(() => {
        if (is_hidden !== undefined) {
            return is_hidden
        }
        if (id === login_id) return false
        if (privacy_level === UserPrivacy.Private || privacy_level === UserPrivacy.CompletelyPrivate) return true
        return is_following !== true
    }, [is_following, is_hidden, privacy_level, id, login_id])
    /*
        포트폴리오를 보여주면 안될 때 사용한다.
     */
    const isBlockPortfolio = useMemo(() => {
        if (is_hidden === false) {
            return is_hidden
        } else if (id === login_id) return false
        else if (privacy_level === UserPrivacy.Private || privacy_level === UserPrivacy.CompletelyPrivate) return true
        return false
    }, [privacy_level, id, login_id, is_hidden])

    return {isHidePortfolio, isBlockPortfolio}
}

export default usePortfolioPrivacy
