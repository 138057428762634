import {useMemo} from 'react'
import {isEmptyArray, isNotEmptyArray} from '@util/array'
import {IMe} from '@type/profile'
import useQueryGetExchanges from '@hook/query/core/exchange/useQueryGetExchanges'
import useQueryMyBots from '@hook/query/bot/useQueryMyBots'

const DAY_IN_SECOND = 86400
const useProStatus = (me: IMe, exchange?: string) => {
    const {data} = useQueryMyBots('copybot')
    const {getExchange} = useQueryGetExchanges()

    const isExpire = useMemo(() => {
        const now = new Date().getTime() / 1000
        const expireTime = new Date(me?.pro_until)?.getTime() / 1000
        return expireTime < now
    }, [me?.pro_until])

    const expireDays = useMemo(() => {
        const now = new Date().getTime() / 1000
        const expireTime = new Date(me?.pro_until)?.getTime() / 1000
        const result = Math.round((expireTime - now) / DAY_IN_SECOND)
        return result === 0 ? 1 : result
    }, [me?.pro_until])

    const proCopyExchange = useMemo(() => {
        if (isEmptyArray(data?.bots)) return undefined
        const copyKeys = data?.bots?.filter(b => b?.status === 'running').map(k => k?.exchange)
        if (exchange && isNotEmptyArray(copyKeys)) {
            const targetExchange = getExchange(exchange)
            return targetExchange.copier_require_ip ? targetExchange?.display_name : undefined
        }
        const proCopyList = copyKeys
            ?.map(e => getExchange(e))
            ?.filter(e => e?.copier_require_ip)
            ?.map(e => e?.display_name)
        return proCopyList?.join(', ')
    }, [data?.bots, exchange])

    return {isExpire, expireDays, proCopyExchange: proCopyExchange}
}

export default useProStatus
